function initLoadMore() {
    console.log('initLoadMore', 'web/js/load-more-collection.js');
    const container = document.querySelector('.product-grid__container');
    const loadMoreButton = document.getElementById('load-more-button');
    const loadingIndicator = document.getElementById('dynamic-loading-animation');
    let isLoading = false;

    if (loadMoreButton) {
        // Initial load on page load
        loadMoreItems();

        // Existing click event listener
        loadMoreButton.addEventListener('click', loadMoreItems);

        // Create intersection observer for auto-loading
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !isLoading) {
                    loadMoreItems();
                }
            });
        }, {
            rootMargin: '300px',
            threshold: 0.5
        });

        // Observe the load more button
        observer.observe(loadMoreButton);
    }

    async function loadMoreItems() {
        if (isLoading) return;

        isLoading = true;
        loadMoreButton.style.display = 'none';
        loadingIndicator.classList.add('visible');

        const currentPage = parseInt(loadMoreButton.dataset.currentPage);
        const totalPages = parseInt(loadMoreButton.dataset.totalPages);
        const nextPage = currentPage + 1;

        try {
            const params = new URLSearchParams(window.location.search);
            params.set('page', nextPage);

            const response = await fetch(`${window.location.pathname}?${params.toString()}`);
            const html = await response.text();

            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            const newContent = doc.querySelector('#collection-filter-results-initial');

            if (newContent) {
                Array.from(newContent.children).forEach(item => {
                    container.appendChild(item.cloneNode(true));
                });

				if (typeof window.initializeQuickAddToCart === 'function') {
					window.initializeQuickAddToCart();
				}
            }

            // Update button's current page
            loadMoreButton.dataset.currentPage = nextPage;

            // Hide button if we've reached the last page
            if (nextPage >= totalPages) {
                loadMoreButton.parentElement.remove();
            } else {
                loadMoreButton.style.display = 'inline-block';
            }
            

        } catch (error) {
            console.error('Error loading more items:', error);
            loadMoreButton.style.display = 'inline-block';
        } finally {
            isLoading = false;
            let overlay = document.getElementById('loading-overlay');
            if (overlay) {
              overlay.style.display = 'none';
            }
            /* const loadingIndicator = document.getElementById('dynamic-loading-animation');
            loadingIndicator.classList.remove('visible'); */
            loadingIndicator.classList.remove('visible');
            loadingIndicator.classList.remove('visible');
        }
    }

    
}

document.addEventListener('DOMContentLoaded', () => {
    initLoadMore();
});

function initializeQuickAddToCart() {
    const forms = document.querySelectorAll('.wishlist-form');

    forms.forEach((form) => {
        const button = form.querySelector('button[type="submit"]');

        // Avoid adding duplicate event listeners
        if (form.dataset.initialized === 'true') return;
        form.dataset.initialized = 'true';

        form.addEventListener('submit', function (e) {
            e.preventDefault();

            setButtonLoading(button);

            const formData = new FormData(form);

            fetch('/', {
                method: 'post',
                body: formData,
                headers: {
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw new Error(data.error);
                    }
                    console.log('Success:', data);

                    window.updateWishlistDataFromForm(data);
                    window.ajaxReloadCart(
                        form.dataset.productId,
                        data.items[0].elementId,
                        data.items[0].wishlistVariantOption,
                        data.items[0].listId,
                        button
                    );
                })
                .catch((error) => {
                    alert('Error: ' + error.message);
                });
        });
    });
}

// Expose the function globally
window.initializeQuickAddToCart = initializeQuickAddToCart;
initializeQuickAddToCart();
window.initLoadMore = initLoadMore;